
import { request as axios } from '@/util/request';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Moment } from 'moment';
import moment from 'moment';
import { PaginationData } from '@/api/operation/customer';
import { RefundData, initRefundData } from '@/api/rider/riderList';
interface Form {
  rider_value: string; //提现单号
  search_value: string; //骑手输入框
  search_type: number; //选择骑手姓名或者电话
  reason_value: string; //备注
  order_time: Moment[]; //申请时间
  refund_type: number; //状态
}
const SEARCH_TYPE = [
  {
    id: 0,
    value: '骑手ID',
  },
  {
    id: 1,
    value: '骑手姓名',
  },
  {
    id: 2,
    value: '骑手电话',
  },
];
const REFUND_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待审核',
  },
  {
    id: 20,
    value: '审核通过',
  },
  {
    id: 10,
    value: '审核不通过',
  },
];
function getFormData() {
  return {
    rider_value: '',
    search_value: '',
    search_type: 0,
    reason_value: '',
    order_time: [],
    refund_type: 0,
  };
}
@Component({
  name: 'Withdrawal',
})
export default class Withdrawal extends Vue {
  is_loading: boolean = false;
  audioPlay = false; //是否开始自动播放
  form: Form = getFormData();
  ware_category_list: any = []; //意向区域
  tider_table: any;
  rider_dis: boolean = false; //提现订单弹窗显隐
  shenheYes: number = 20; //审核是否通过
  //shenhePass: boolean=false//审核不通过字段显隐
  passBecause: string = ''; //备注
  shenhe_dis: boolean = false; //审核弹窗显隐
  koukuan_money_err: boolean = false; //扣款错误提示
  koukuan_money: number = 0; //扣款金额
  passBecause_err: boolean = false; //备注提示
  result_dis: boolean = false; //审核结果弹窗是否显示
  result = [
    {
      name: '审核结果',
      body: '不通过',
    },
    {
      name: '扣款金额（元）',
      body: 10,
    },
    {
      name: '备注',
      body: '客诉扣款吧啦啦啦',
    },
    {
      name: '审核人',
      body: 'fgajkfdjak',
    },
    {
      name: '审核时间',
      body: '2022-02',
    },
  ];
  /**
   * @description 验证是否最多两位小数的数字
   * @param {string} number
   * @returns {number}
   */
  validateDouble(number: string): boolean {
    // if (Number(number) === 0) return false;
    return /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(number);
  }
  //输入框改变校验
  inputChange(type: string) {
    if (type == 'money') {
      if (String(this.koukuan_money) == '' || !this.validateDouble(String(this.koukuan_money))) {
        this.koukuan_money_err = true;
        return false;
      } else {
        this.koukuan_money_err = false;
        return true;
      }
    } else if (type == 'beizhu') {
      if (this.koukuan_money > 0 && this.passBecause == '') {
        this.passBecause_err = true;
        return false;
      } else {
        this.passBecause_err = false;
        return true;
      }
    }
  }
  //获取数据
  async getData(body?: any) {
    const bodys: any = {
      ...body,
      current: this.current,
      limit: 10,
    };
    const res: any = await axios.get('/boss/knight/withdraw', {
      params: {
        ...bodys,
      },
    });
    this.refund_data = res.data;
    console.log(res);
  }
  //搜索
  async find() {
    const send_data: any = {
      withdraw_number: this.form.rider_value,
      register_id: this.form.search_value,
      name: this.form.search_value,
      mobile: this.form.search_value,
      apply_start_time: Math.floor(
        +moment(this.form.order_time[0]).startOf('day').format('x') / 1000
      ),
      apply_end_time: Math.floor(+moment(this.form.order_time[1]).endOf('day').format('x') / 1000),
      remark: this.form.reason_value,
    };
    if (this.form.rider_value == '') {
      delete send_data.withdraw_number;
    }
    if (this.form.search_value == '') {
      delete send_data.register_id;
      delete send_data.name;
      delete send_data.mobile;
    }
    if (this.form.search_type == 0) {
      delete send_data.name;
      delete send_data.mobile;
    } else if (this.form.search_type == 1) {
      delete send_data.register_id;
      delete send_data.mobile;
    } else if (this.form.search_type == 2) {
      delete send_data.register_id;
      delete send_data.name;
    }
    if (this.form.reason_value == '') {
      delete send_data.remark;
    }
    if (this.form.order_time.length < 0) {
      delete send_data.apply_start_time;
      delete send_data.apply_end_time;
    }
    await this.getData(send_data);
  }
  //清空
  clearn() {
    this.form.rider_value = '';
    this.form.search_value = '';
    this.form.order_time = [];
    this.form.reason_value = '';
    this.form.search_type = 0;
  }
  //切换状态
  changeStatus(id: any) {
    const body = {
      status: id,
    };
    this.getData(body);
  }
  //点击提现订单数
  async clickRider(record: any) {
    console.log(record);
    const body: any = {
      withdraw_id: record.id,
      current: this.txcurrent,
    };
    const res = await axios.get('/boss/knight/withdraw/order', {
      params: {
        ...body,
      },
    });
    console.log(res);
    this.tider_table = res.data;
    this.rider_dis = true;
  }
  //点击审核
  shenheid: any;
  shenheTableid: any;
  async clickShenhe(record: any) {
    this.shenhe_dis = true;
    this.shenheid = record.id;
    this.shenheTableid = record.qualification.id;
  }
  //点击审核结果
  shenheResult(id: string) {
    this.result_dis = true;
    const res: any = this.refund_data.detail.filter((item: any) => {
      return item.id == id;
    });
    this.result[0].body = res[0].status == 20 ? '审核通过' : '不通过';
    this.result[1].body = res[0].cut_money / 100;
    this.result[2].body = res[0].operation_remark;
    this.result[3].body = res[0].admin_user;
    this.result[4].body = moment(res[0].operation_time * 1000).format('YYYY-MM-DD HH:mm:ss');
    console.log(JSON.parse(JSON.stringify(res)));
    console.log(this.result);
  }
  //确定
  handleOk() {
    this.rider_dis = false;
  }
  //取消
  handleCancel() {
    this.rider_dis = false;
  }
  //审核确定
  async shenheOk() {
    if (!this.inputChange('money') || !this.inputChange('beizhu')) {
      this.$message.error('请检查必填项');
    } else {
      this.shenhe_dis = false;
      //后端接口
      const body = {
        id: this.shenheid,
        status: this.shenheYes,
        remark: this.passBecause,
        cut_money: this.koukuan_money * 100,
      };
      const res = await axios.put(`/boss/knight/withdraw/${this.shenheid}`, body);
      if (res.status == 200) {
        this.shenhe_dis = false;
        this.$message.success('审核成功');
      } else {
        this.$message.error('审核失败');
      }
      await this.getData();
    }
  }
  //审核取消
  shenheCancel() {
    this.shenhe_dis = false;
  }
  //搜索条件
  get searchType() {
    return SEARCH_TYPE;
  }
  //骑手状态
  get refundStatus() {
    return REFUND_STATUS;
  }
  refund_data: PaginationData<RefundData> = initRefundData();
  //分页
  current: number = 1;
  paginationOption(data: any) {
    this.current = data.current || 1;
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  txcurrent: number = 1;
  paginationOptions(data: any) {
    this.txcurrent = data.current || 1;
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  async created() {
    await this.getData();
  }
  changeTime(val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss');
  }
}
